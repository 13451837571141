@import "util.scss";
.container {
  position: absolute;
  width: 100%;
  padding: 16px;
  bottom: 0;
  left: 0;
  font-size: remify(14px);
  line-height: var(--line-height);

  @include respond-to(MIN_768) {
    font-size: remify(16px);

  }
}

.GarageSceneQuestions {
  position: relative;
  max-width: 610px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.GarageSceneResponseContainer {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.GarageSceneResponse {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 8px;
  @include respond-to(MIN_768) {
    height: auto;
  }
  font-weight: 400;
}

.textCenter {
  text-align: center;
  position: relative;
  margin-bottom: 80px;
}
.textCenter::after {
  content: "";
  position: absolute;
  z-index: -1;
  height: 300px;
  width: 300px;
  top: 50%;
  left: 50%;
  border-radius: 1000px;
  transform: translate(-50%, -50%);
  overflow: hidden;
  background-image: url(../../../../public/thinking.gif);
  background-size: cover;
  background-position: center center;
  box-shadow: 0 0 40px 50px #0b0000;
}

.form {
  width: 100%;
  margin: auto;
  padding: 8px;

  @include respond-to(MIN_768) {
    margin: 8px auto 80px;
  }
}

.flexContainer {
  display: flex;
  align-items: center;
  position: relative;
  //gap: 8px;
  @include respond-to(MIN_1024) {

  }
}

.helpButton {
  flex-shrink: 0;
  height: calc(var(--input-height) - 8px);
  width: calc(var(--input-height) - 8px);
  @include respond-to(MIN_1024) {
    position: absolute;
    right: calc(100% + .5 * (var(--input-height) - 8px));
    transform: translateX(50%);
  }
}

.submitButton {
  flex-shrink: 0;
  position: absolute;
  --submit-button-offset: 4px;
  bottom: var(--submit-button-offset);
  right: var(--submit-button-offset);
  height: calc(var(--input-height) - var(--submit-button-offset) * 2);
  width: calc(var(--input-height) - var(--submit-button-offset) * 2);

  @include respond-to(MIN_1024) {
    --submit-button-offset: 8px;
  }
}

.questionTextArea {
  background-color: #E3E4E5;
  color: #000000;
  border: none;
  border-radius: 20px;
  padding: 9px 60px 9px 20px;
  width: 100%;
  flex-grow: 1;
  height: var(--input-height);
  font-weight: 400;
  overflow: hidden;
  resize: none;

  &::placeholder {
    color: #000000;
  }

  @include respond-to(MIN_1024) {
    border-radius: 26px;
    padding: 15px 60px 15px 20px;
  }
}

.newsletterButtonContainer {
  padding: 0 var(--space-16);
  overflow: hidden;
}

.newsletterButton {
  width: 100%;
}