@import "util.scss";
.cutSceneVideo {
  width: 100%;
  height: 100%;
  object-fit: cover;

}
.hidden {
  display: none;
  opacity: 0;
}
.half {
  background: rgba(0,0,0,0.9);
  display: flex;

  video {
    height: 50% !important;
    align-self: center;

    @include respond-to(MIN_768) {
      height: 100% !important;
    }
  }
}
