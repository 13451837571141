@import "util.scss";
.suggestedQuestions {
  max-width: 600px;
  gap: .5em;
  margin: 0 auto;
  display: grid;
  width: 100%;
  padding: 8px;

  grid-template-columns: 1fr;
  overflow: hidden;
  //@include respond-to(MIN_1024) {
  //  grid-template-columns: 1fr 1fr;
  //}

  button {
    background: rgba(250, 250, 250, 0.15);
    backdrop-filter: blur(15px);
    color:white;
    border: none;
    border-radius: 16px;
    padding: 2px 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
    text-align: start;
    min-height: 40px;

    @include hover() {
      background-color: #ffffff;
      color: #626669;
    }

    @include respond-to(MIN_768) {
      background: rgba(98, 102, 105, 0.2);
    }

    @include respond-to(MIN_1024) {
      border-radius: 26px;
      font-size: 16px;
      line-height: 24px;
      min-height: 56px;
      padding: 0 20px;
    }
  }
}
