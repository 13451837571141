@import "util.scss";
.garageScene {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.redGlow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: transparent;
  background: radial-gradient(#ff0000 0%, transparent 55%);
  width: max(100vw, 65vh);
  height: max(100vw, 65vh);
  opacity: 0.6;
  mix-blend-mode: color;
  animation: redGlow 1s infinite alternate ease-in-out;

  @include respond-to(MIN_768) {
    width: max(100vw, 100vh);
    height: max(100vw, 100vh);
  }
  &.playing {
    animation-duration: .4s;
  }
}

.background {
  position: absolute;
  top: var(--header-height);
  left: 0;
  width: 100%;
  height: 65%;

  img {
    object-fit: cover;
  }

  @include respond-to(MIN_768) {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
  }
}

@keyframes redGlow {
  0% {
    opacity: 0.55;
  }
  100% {
    opacity: 0.7;
  }
}