@import "util.scss";
.answer {
  position: relative;
  margin-bottom: 10px;
  padding: 16px;

  > div {
    height: auto;
    max-height: 33vh;
    min-height: 160px;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
    margin-top: auto !important;
    padding-top: 25px;
    mask-image: linear-gradient(to bottom, transparent 0%, black 50%, black 100%);

    @include respond-to(MIN_768) {
      //height: auto;
      padding-top: 0;
    }

    p {
      flex-shrink: 0;
      width: 100%;
      margin: auto;
      margin-bottom: 0;
    }
  }

  .answerText {
    padding-top: 100px;
    margin-top: 100px;
    flex-shrink: 0;
    white-space: pre-line;
    word-break: break-word;

    a {
      text-decoration: underline;
    }
  }

  @include respond-to(MIN_768) {
    &:after {
      background: none;
    }
  }
}

.aiThinking {
  width: 100%;
  text-align: center;
  margin: 0;
}
