@import "util.scss";
.container {
  position: fixed;
  left: 50%;
  //top: 100%;
  bottom: 0;
  transform: translateX(-50%) translateY(0%);
  width:100%;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-weight: 400;
  z-index:100;
  min-height:50px;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  transition-delay: 0.7s;
  opacity:0;

  &.visible{
    opacity:1;
    transform: translateX(-50%) translateY(0%);
  }
  
}

.buttonBottom {

  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);

  width: 100%;
  padding: 12px 0;
  text-align: center;
  transition: background 0.15s ease-in-out;

  &:hover{
    background-color: black;
  }

  &.desktop{
    width:auto;
    padding: 12px 18px;
    border-radius:100px;
    background: rgba(0, 0, 0, 0.0);
    margin: var(--grid-gutter) var(--grid-gutter);

    &:hover{
      background: rgba(0, 0, 0, 1);
    }
  }
}
