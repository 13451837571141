@import "util.scss";
.layout {
  overflow: hidden;

  @include respond-to(MIN_768) {
     -ms-overflow-style: none;  /* Internet Explorer 10+ */
     scrollbar-width: none;  /* Firefox */

     &::-webkit-scrollbar {
       display: none;  /* Safari and Chrome */
       width: 0;
       height: 0;
     }
   }
}

.loaderContainer {
  background: black;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.garage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.cutSceneCanvas {
  background-color: black;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: zindex($zLayout, cutScene);
  pointer-events: none;
  display: none;
  opacity: 0;

  > div {
    position: relative;
    width: 100%;
    height: 100%;
    //display: contents
  }
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: black;
  }
}

.garageOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: zindex($zLayout, overlay);

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: -1;
    background: linear-gradient(transparent 60%, rgba(0,0,0,0.3));
  }
}

.garageCanvasContainer {
  position: relative;
  z-index: zindex($zLayout, canvas);
  width: 100%;
  margin-top: var(--header-height);
  height: calc(100% - var(--header-height));

  @include respond-to(MIN_768) {
    * {
      -ms-overflow-style: none;  /* Internet Explorer 10+ */
      scrollbar-width: none;  /* Firefox */

      &::-webkit-scrollbar {
        display: none;  /* Safari and Chrome */
        width: 0;
        height: 0;
      }
    }
  }
}

.garageBackground {
  z-index: zindex($zLayout, background);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
