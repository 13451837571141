@import "util.scss";
.container {
  position: fixed;
  left: 50%;
  //top: 100%;
  bottom: 40px;
  transform: translateX(-50%);
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  opacity: 0;
}

.indicator {
  margin-top: 8px;
  display: block;
  width: 2px;
  height: 40px;
  background: rgba(255, 255, 255, 0.2);
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom:50%;
    width: 100%;
    background: #fff;
    animation: 1.75s ease-out infinite running slide;
  }
}

@keyframes slide {
  0% {
    top: 0;
    bottom:100%;
  }

  80% {
    top: 0;
    bottom:0%;
  }
  90% {
    bottom: 0;
    top: 50%;
  }
  100% {
    top: 100%;
    bottom: 0;
  }
}