@import "util.scss";
.container {
  perspective: 1000px;
}

.view {
  font-size: 110px;
  @media (min-width: 768px) {
    font-size: 250px;
  }

  height: var(--screen-height);
  width: 100vw;
  display: flex;
  justify-content: center;
  perspective: 1000px;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 370px;
    transform: translateY(-50%);
    background: linear-gradient(180deg, #000000 17.73%, transparent 40.5%, transparent 68.3%, #000000 91.85%);
    z-index: 1000;

    @media (min-width: 768px) {
      top: 0;
      height: var(--screen-height);
      transform: translateY(0);
    }
  }
}

.wheelContainer {
  position: relative;
  perspective: 1000px;
  display: flex;
  justify-content: center;
}

// Needed for Safari
.shadowsContainer {
  position: absolute;
}

.background {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #000000 17.73%, rgba(0, 0, 0, 0) 40.5%, rgba(0, 0, 0, 0) 68.3%, #000000 91.85%);
}

.wheel {
  opacity: 0;
  position: relative;
  transform-style: preserve-3d;
  transform-origin: 50% 50%;
  text-align: center;
}

.number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: 50% 50%;
  transform-style: preserve-3d;
  //transform: translate(-50%, -50%);
  backface-visibility: hidden;
  line-height: 1em;
  //border: solid 1px blue;
  font-weight: 700;
}