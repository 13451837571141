@import "util.scss";
.loaderOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: zindex($zLayout, loaderOverlay);
  padding-top: var(--header-height);
  overflow: hidden;
  transition: opacity 500ms;

  &.hidden {
    pointer-events: none;
    opacity: 0;
  }
}

.textContainer {
  opacity: 0;
  position: absolute;
  z-index: 1;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include respond-to(MIN_768) {
    top: 60%;
  }
}

.logo {
  max-width: 98vw;
  position: relative;
  margin-bottom: var(--space-24);
}

.title {
  @include heading-01();
  margin-bottom: var(--space-24);

  @media (min-width: 768px) {
    font-size: 5rem
  }
}

.subtitle {
  font-size: 1rem;
  font-weight: 400;
  max-width: 90vw;
  margin-bottom: var(--space-24);

  @media (min-width: 768px) {
    font-size: 1.5rem;
    margin-bottom: var(--space-32);
  }
}

.backgroundContainer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: black;
  .background {
    opacity: 0;
    object-fit: cover;
  }
}